import React from 'react'
import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import { useSelector } from 'react-redux'
import { isSafari } from 'utils/constants'

const MoonpayProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)

  if (sessionData?.sessionData?.serviceProviderUrl !== '' && sessionData?.sessionData?.paymentMethodType === 'APPLE_PAY' && isSafari) {
    window.location.href = sessionData?.sessionData?.serviceProviderUrl
  }

  return (
    <LogoPageWrapper>
      <iframe
        id="moonpay-iframe"
        title="moonpay-iframe"
        allow="accelerometer; autoplay; camera; microphone; gyroscope; payment"
        frameBorder="0"
        src={sessionData.sessionData.serviceProviderUrl}
        style={{ width: '100%', maxWidth: 430, height: '100%', minHeight: 629, background: 'transparent' }}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </LogoPageWrapper>
  )
}

export default MoonpayProvider

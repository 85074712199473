import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getURLParams } from 'utils/data-model'
import { setSessionData, setDisplayPage, setToken, showGlobalAlert, setQueryParams } from 'store/slices/app'
// import LogoPageWrapper from '../ui/logo-page-wrapper'
import { useGetSessionData, /* useGetServiceProviderProperties, useGetServiceProviderDetails, useGetCountryByServiceProvider, useGetFiatCurrencyByProvider, useGetCryptoCurrencyByProvider, useGetPaymentMethodsByProvider, useGetServiceProviderCurrencyCode */ } from 'hooks/api'
import Loader from '../ui/loader'
import { setServiceProviderProperties /* setServiceProviderDetails */ } from 'store/slices/serviceProvider'

import _ from 'lodash'

const Landing = () => {
  const dispatch = useDispatch()
  const { token, sessionData } = useSelector((state) => state.app)
  const qparams = getURLParams()
  const { isFetched: hasFetchedSessionData, data: sessionQuery } = useGetSessionData(!!token)

  // Handle query params, stash them in Redux
  useEffect(() => {
    // If this changes, make sure to update it in the theme provider
    if (window.self === window.top && !qparams.token && (qparams.p || qparams.status) && sessionStorage.getItem('token')) {
      qparams.token = sessionStorage.getItem('token')
    }
    dispatch(setQueryParams(qparams))

    // If this changes, make sure to update it in the theme provider
    if (!qparams.token && !qparams.status && !qparams.orderId) {
      dispatch(setDisplayPage('wizard'))
    }
    dispatch(setToken(qparams.token))
  }, [])

  // Stash all of the fetched session & provider data in redux
  useEffect(() => {
    // If the session query fails, we're not going to do any other fetching
    if (hasFetchedSessionData && !sessionQuery?.sessionData) {
      dispatch(showGlobalAlert({
        type: 'error',
        content: 'Missing session data',
        persist: true
      }))
      dispatch(setDisplayPage('error'))
      // window.top.location.href = returnUrl // PYMT-844
      return
    }

    // If we're not done all of the fetching, get out of here
    if (!hasFetchedSessionData /* || !hasFetchedServiceProviderDetails  || !hasFetchedServiceProviderProperties || !hasFetchedServiceProviderCurrencyCode */) {
      return
    }
    const serviceProvideProps = {
      countries: [],
      fiatCurrencies: [],
      cryptoCurrencies: [],
      paymentMethods: []
    }
    dispatch(setServiceProviderProperties(serviceProvideProps))
    // dispatch(setServiceProviderDetails(serviceProviderDetails[0]))

    if (sessionQuery?.sessionData?.sessionType === 'SELL') {
      sessionQuery.sessionData.serviceProviderSourceCurrencyCode = sessionQuery?.sessionData?.sourceCurrencyCode
    } else {
      sessionQuery.sessionData.serviceProviderDestinationCurrencyCode = sessionQuery?.sessionData?.destinationCurrencyCode
    }
    // sessionQuery.sessionData.cryptoChain = serviceProviderCurrencyResult?.cryptoChain

    dispatch(setSessionData(sessionQuery))
    if (sessionQuery?.sessionData?.paymentMethodType === 'APPLE_PAY') {
      window.open(sessionQuery?.sessionData?.serviceProviderUrl, '_self')
    }
  }, [hasFetchedSessionData, /* hasFetchedServiceProviderProperties, hasFetchedServiceProviderDetails, hasFetchedServiceProviderCurrencyCode */])

  // Navigate to the "landing" page within most widgets
  useEffect(() => {
    if (!hasFetchedSessionData || _.isEmpty(sessionData)) {
      return
    }
    dispatch(setDisplayPage('purchase'))
  }, [sessionData])

  return (
    <>
    {sessionQuery?.sessionData?.serviceProvider && sessionQuery?.sessionData?.serviceProvider !== 'RAMP' &&
      // <LogoPageWrapper subtext="">
        <Loader />
      // </LogoPageWrapper>
    }
    </>
  )
}

export default Landing

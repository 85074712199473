import { createSlice } from '@reduxjs/toolkit'
import { getURLParams } from 'utils/data-model'
import _ from 'lodash'

const urlParams = getURLParams()
const themeParam = urlParams.theme && (urlParams.theme === 'lightMode' || urlParams.theme === 'darkMode')
// This is our current logic for whether we'll end up in the Wizard
let isWizard = !urlParams.token && !urlParams.status && !urlParams.orderId && (!urlParams.p || !sessionStorage.getItem('token'))
let wizardBackgroundColor = sessionStorage.getItem('wizardBackgroundColor') ? sessionStorage.getItem('wizardBackgroundColor') : 'transparent'
wizardBackgroundColor = history?.state?.countryCode ? sessionStorage.getItem('wizardBackgroundColor') : wizardBackgroundColor

const common = {
  accountLogoUrl: isWizard ? null : `${process.env.PUBLIC_URL}/account/meld/logo-main.svg`,
  buttonColor: '',
  darkModeButtonColor: '',
  buttonTextColor: '',
  fontSizes: {
    title: '20px',
    large: '16px',
    medium: '14px',
    small: '12px',
  },
  colors: {
    // Success/Warning/Error/etc
    success: '#49aa19',
    warning: '#d34041',
    danger: '#f30d0d',
    dangerous: '#ff2222',
    filled: '#f969d3',
    empty: '#d9d9d9',
    disabled: '#434343',
    disabledLight: '#c0c0c0',
    antdInputBorder: '#1890ff',
    antdHoverInputBorder: '#40a9ff',
    progressBar: '#1f2351',

    // Global background
    backgroundColor: wizardBackgroundColor,
    backgroundGradient: 'transparent',
    backgroundTertiary: '#ffffff',

    // Branding border colors
    borderColor: 'transparent',
    borderTextColor: '#ffffff',

    // loader background color
    loaderBackgound: '#4f51c02b',
    backButton: '#ffffff',
    tabPrimary: '#F969D3',
    tabforeGroundPrimary: '#ffffff',
    defaultSwitchColor: '#0C111D',
    tabBackgoundPrimary: '#0C111D',
  },
}

const darkMode = {
  // Text/Border Colors
  toggleHighlight: '#F969D3',
  toggleOuterBorder: '#ffffff',
  foregroundPrimary: '#ffffff',
  foregroundSecondary: '#8c8c8c',
  foregroundTertiary: '#070e26',
  foregroundQuaternary:'#94969C',

  defaultBackgroundColor: '#0C111D',

  // Background Colors
  backgroundPrimary: 'transparent',
  backgroundSecondary: '#484e8a',

  // Highlight
  highlightPrimary: '#4f51c0',
  toggleBorder: '#333741',


  // Loader
  loaderSecondary: '#878bb0',
  // Drawer
  drawerBackgoundPrimary: '#0C111D',
  drawerBackgound: '#333257',
  drawerHeaderColor: '#ffffff',
  drawerSearchInput: '#ffffff',
  drawerBodyTitle: '#ffffff',
  drawerListTextColor: '#ffffff',
  drawerClearIcon: '#ffffff',
  drawerListHeader: '#4F4375',
  drawerListBody: '#5D4C84',

  quoteEmphasize: '#7373e1',
  downIcon: '#ffffff',
  quoteSize: '#ffffff',
  cardBackground: '#1F242F',
  cardBorder: '#333741',
  cardPaymentBorder: '#94969C',
  cardPaymentBackground: '#1F242F',
  walletText: '#ffffff',
  errorText: '#db3e33',
  currencyCardBackground: '#0C111D',
  currencyCardBorder: '#333741',
  currencyCardColor: '#ffffff',
  amountCardBackground: '#282748',
  quoteCardBackground: '#161b26',
  quoteFilterSelected: '#f969d3',
  quoterFilterText: '#ffffff',
  quoteCard: '#1F242F',
  quoteDropSection: '#0C111D',
  labelColor: '#ACACAC',
  quoteHover: '#ffffff',
  labelColorSecondary:'#CECFD2',
  labelColorPrimary:'#ffffff',
  //textColor
  cryptoChainColColor : '#161B26',
  cryptoChainTextColor : '#ffffff',
  buySellBordersColors : '#94969C',
  formTextColor:'#94969C',
  quoteColor: '#ffffff',
  walletBackground: '#1F242F',
  buttonDisabledColor: '#85888e',
  buttonDisabled: '#1f242f',
  quoteBorder: '#94969C',
  quoteBackground: '#0c111d',
  recommendedText: '#ffffff',
  recommendedBorder: '#333741',

  modalBackground: '#0b111e',
  modalHeaderText: '#ffffff',
  modalBodyText: '#80838a',
  modalIcon: '#2e323c',
  modalButtonBorder: '#323740',
  modalButtonTextColor: '#cecfd2',
  modalButtonBackgroundColor: '#161b26',
  copyrightText: '#ffffff',
}

const lightMode = {
  toggleHighlight: '#ffffff',
  toggleOuterBorder: '#d0d5dd',
  // Text/Border Colors
  foregroundPrimary: '#070e26',
  foregroundSecondary: '#737373',
  foregroundTertiary: '#ffffff',
  foregroundQuaternary:'#667085',

  defaultBackgroundColor: '#ffffff',

  // Background Colors
  backgroundPrimary: 'transparent',
  backgroundSecondary: '#787eba',

  // Highlight
  highlightPrimary: '#6e2acd',

  // Loader
  loaderSecondary: '#878bb0',
  // Drawer
  drawerBackgoundPrimary: '#ffffff',
  drawerBackgound: '#ffffff',
  drawerHeaderColor: '#495057',
  drawerSearchInput: '#000000',
  drawerBodyTitle: '#7b7b7b',
  drawerListTextColor: '#000000d9',
  drawerClearIcon: '#a8aaac',
  drawerListHeader: '#ffffff',
  drawerListBody: '#EAF6FB',

  quoteEmphasize: '#6e2acd',
  downIcon: '#485365',
  quoteSize: '#ffffff',
  cardBackground: '#f2f4f7',
  cardBorder: '#d0d5dd',
  cardPaymentBorder: '#65A4C1',
  cardPaymentBackground: '#ffffff',
  walletText: '#000000',
  errorText: '#000000',
  currencyCardBackground: '#ffffff',
  currencyCardBorder: '#d0d5dd',
  currencyCardColor: '#344054',
  amountCardBackground: '#dcefff',
  quoteCardBackground: '#ecedee',
  quoteFilterSelected: '#f969d3',
  quoterFilterText: '#ffffff',
  quoteCard: '#ffffff',
  quoteDropSection: '#68ACE4',
  labelColor: '#667085',
  quoteHover: '#F969D3',
  quoteColor: '#4a5567',
  labelColorSecondary:'#151a25',
  walletBackground: '#ffffff',
  buttonDisabledColor: '#98a2b3',
  buttonDisabled: '#f2f4f7',
  quoteBorder: '#d0d5dd',
  quoteBackground: '#ffffff',
  recommendedText: '#344054',
  recommendedBorder: '#d0d5dd',

  modalBackground: '#ffffff',
  modalHeaderText: '#1a2333',
  modalBodyText: '#5d6b7e',
  modalIcon: '#eceef1',
  modalButtonBorder: '#d7dce2',
  modalButtonTextColor: '#344054',
  modalButtonBackgroundColor: '#ffffff',
  copyrightText: '#000000',
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    darkMode,
    lightMode,
    common,
    locked: !!urlParams.themeLocked,
    activeTheme: urlParams.themeLocked || (themeParam && urlParams.theme) || (isWizard ? (localStorage.getItem('widgetTheme') === 'lightMode' ? 'lightMode' : 'darkMode') : 'darkMode') || localStorage.getItem('theme'),
    themeBackgroundColor: 'transparent',
  },
  reducers: {
    setTheme: (state, action) => {
      action.payload === 'lightMode' ? localStorage.setItem('theme', 'darkMode') : localStorage.removeItem('theme')
      return {
        ...state,
        activeTheme: action.payload
      }
    },
    updateCommonTheme: (state, action) => ({
      ...state,
      common: {
        ...state.common,
        ..._.pickBy(action.payload, (val) => val ?? false),
        colors: {
          ...state.common.colors,
          ..._.pickBy(action.payload.colors, (val) => val ?? false)
        }
      }
    }),
    updateDarkTheme: (state, action) => ({
      ...state,
      darkMode: {
        ...state.darkMode,
        ..._.pickBy(action.payload, (val) => val ?? false),
      }
    }),
    updateLightTheme: (state, action) => ({
      ...state,
      lightMode: {
        ...state.lightMode,
        ..._.pickBy(action.payload, (val) => val ?? false),
      }
    }),
    updateThemeBackgroundColor: (state, action) => {
      state.themeBackgroundColor = action.payload
    },
  },
})

export const { setTheme, updateCommonTheme, updateDarkTheme, updateLightTheme, updateThemeBackgroundColor } = themeSlice.actions

// Selecting the theme actually involves building the theme from our latest data
export const selectTheme = (state) => {
  // Default to light mode just in case we break things
  const themeName = state?.theme?.activeTheme || 'lightMode'
  const darkLightTheme = state?.theme?.[themeName]

  return {
    name: themeName,
    ...state.theme.common,
    colors: {
      ...state.theme.common.colors,
      ...darkLightTheme,
      // URL parameters override everything, so we can get the wizard to show up with the correct settings in the dashboard preferences
      borderColor: urlParams.borderColor ?? state.theme.common.colors.borderColor,
      borderTextColor: urlParams.borderTextColor ?? state.theme.common.colors.borderTextColor,
      backgroundColor: urlParams.backgroundColor ?? state.theme.common.colors.backgroundColor,
      backgroundPrimary: (themeName === 'darkMode' ? urlParams.darkModeBackground : urlParams.lightModeBackground) ?? darkLightTheme.backgroundPrimary
    },
    buttonColor: urlParams.buttonColor ?? state.theme.common.buttonColor,
    darkModeButtonColor: urlParams.darkModeButtonColor ?? state.theme.common.darkModeButtonColor,
    buttonTextColor: urlParams.buttonTextColor ?? state.theme.common.buttonTextColor,
  }
}

export const selectThemeName = (state) => state.theme.activeTheme
export const selectThemeLocked = (state) => state.theme.locked
export const initBackgroundColor = '#0c112a'
export const initBorderColor = '#0c112a'
export const initBorderTextColor = '#ffffff'
export const initAccountLogo = '/images/meld-logo.svg'
export const initBackgroundGradient = 'linear-gradient(85.65deg, rgba(96, 161, 191, 0.4) -8.33%, rgba(98, 176, 211, 0) 43.57%), linear-gradient(288.9deg, rgba(249, 104, 211, 0.6) -79.18%, rgba(249, 104, 211, 0) 55.01%)'
export const initBackgroundPrimary = '#252847'

export default themeSlice.reducer
